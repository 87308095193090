import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
    const [records, setRecords] = useState([]);
    const [form, setForm] = useState({ id: '', name: '', otherField: '' });

    useEffect(() => {
        fetchRecords();
    }, []);

    const fetchRecords = async () => {
        const response = await axios.get('http://localhost:3001/records');
        setRecords(response.data);
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (form.id) {
            await axios.put(`http://localhost:3001/records/${form.id}`, form);
        } else {
            await axios.post('http://localhost:3001/records', form);
        }
        fetchRecords();
        setForm({ id: '', name: '', otherField: '' });
    };

    const handleEdit = (record) => {
        setForm(record);
    };

    const handleDelete = async (id) => {
        await axios.delete(`http://localhost:3001/records/${id}`);
        fetchRecords();
    };

    return (
        <div>
            <h1>Ninox CRUD App</h1>
            <form onSubmit={handleSubmit}>
                <input name="name" placeholder="Name" value={form.name} onChange={handleChange} />
                <input name="otherField" placeholder="Other Field" value={form.otherField} onChange={handleChange} />
                <button type="submit">Save</button>
            </form>
            <ul>
                {records.map(record => (
                    <li key={record.id}>
                        {record.name} - {record.otherField}
                        <button onClick={() => handleEdit(record)}>Edit</button>
                        <button onClick={() => handleDelete(record.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default App;
